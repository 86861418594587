.news {
  &__editor {
    &__form {
      &__title {
        input {
          font-size: 24px !important;
        }
      }
    }

    &__toolbar {
      button {
        width: 100%;
      }
    }
  }
}
