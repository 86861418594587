.editor {
  &__controls {
    button {
      width: 100%;
      margin: 0;
    }

    hr {
      margin-top: 0;
      margin-bottom: 20px;
    }

    &__live,
    &__not-live,
    &__preview,
    &__pin-btn {
      display: inline-block;
      //height: 100%;
      padding: 4px 8px 4px 4px;
      color: white;
      border-radius: 5px;
      white-space: nowrap;
      margin-right: 10px;
      margin-bottom: 10px;
    }

    &__live {
      background-color: $primary;
    }

    &__preview,
    &__pin-btn {
      background-color: #f2f4f7;
      color: $primary;
    }

    &__not-live {
      background-color: red;
    }
  }

  &__no-instances {
    margin: auto;
    width: 100%;
    text-align: center;

    img {
      width: 40%;
      opacity: 0.5;
    }
  }
}
