.admin {
  &__image-diff {
    img {
      width: 20%;
      height: 100px;
      margin-right: 2.5%;
      margin-top: 3px;
    }
  }
}

ins {
  background-color: lightgreen;
  text-decoration: none;
  border-radius: 3px;
  padding: 1px;
  color: black;
}

del {
  background-color: orangered;
  text-decoration: none;
  border-radius: 3px;
  padding: 1px 3px;
  color: black;
}

.diffins-img,
.diffdel-img {
  width: 20%;
  margin-right: 2.5%;

  img {
    margin-top: 0;
    width: 100%;
    height: 100px;
    object-fit: cover;
    border: none;
  }
}

.diffins-img {
  border: 3px solid lightgreen;

  img {
  }
}

.diffdel-img {
  border: 3px solid orangered;

  img {
    opacity: 0.5;
  }
}
