.events {
  &__editor {
    &__form {
      &__title {
        input {
          font-size: 24px !important;
        }
      }

      &__datepicker {
        overflow-y: visible;

        .tabs {
          overflow: visible;
        }

        .react-datepicker__input-container {
          &::before {
            content: '\00a0';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            pointer-events: none;
          }

          &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            transform: scaleX(0);
            transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            pointer-events: none;
          }
        }
        input {
          border: none;
          color: #646777;
          font-size: 16px !important;
          width: 100%;
        }
      }
    }

    &__toolbar {
      button {
        width: 100%;
      }
    }
  }
}
