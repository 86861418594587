$primary: #209399;
$primary-light: lighten($primary, 5%);

$theme-colors: (
  "primary": $primary,
);

$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 800px,
  lg: 1000px,
  xl: 1400px
);

.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
  color: white !important;

  &::before {
    background-color: $primary-light !important;
    border-color: $primary-light !important;
  }

  &:hover {
    border-color: $primary-light !important;
  }
}


.btn-outline-primary {
  border-color: $primary !important;
  color: $primary !important;

  &::before {
    background-color: transparent !important;
    border-color: $primary-light !important;
  }

  &:hover {
    border-color: $primary-light !important;
    //color: white !important;
  }

  &:active {
    border-color: $primary !important;
    color: $primary !important;
  }
}

.btn-outline-secondary {
  &::before {
    background-color: transparent !important;
  }

  &:hover {
    //border-color: $primary-light !important;
    //color: white !important;
  }

  &:active {
    //border-color: $primary !important;
    //color: $primary !important;
  }
}

.bg-white-darker {
  background-color: darken(white, 2%);
}

.pill-primary {
  background-color: $primary;
  color: white;

  svg {
    color: white;
  }
}

.pill-danger {
  background-color: #dc3545;
  color: white;

  svg {
    color: white;
  }
}

.pill-secondary {
  background-color: #f4f4f4;
  color: $primary !important;

  svg {
    color: $primary;
  }
}
