.sections {
  &__editor {
    &__form {
      &__subsection {
        display: flex;
        justify-content: space-between;
        background-color: darken(white, 5%);
        padding: 7px;
        margin-bottom: 5px;
        width: 100%;
      }

      &__navbar {
        background-color: $primary;

        .nav-item {
          color: white;
          margin-right: 20px;
        }
      }

      &__sidemenu {
      }

      &__tree-item {
        margin-bottom: 5px;
        padding-left: 5px !important;
        background-color: #fdfdfd;
        //border-left: 2px solid $primary;

        &.top {
          //border-left: 4px solid $primary;
          background-color: white;

          p {
            font-size: 1.25rem;
          }
        }

        &.top:not(.first) {
          margin-top: 40px;
        }

        &.first {
          margin-top: 20px;
        }

        &:hover .sections__editor__form__tree-controls {
          visibility: visible;
        }
      }

      &__tree-controls {
        transition: none !important;
        visibility: hidden;
      }
    }
  }
}
